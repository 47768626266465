<template>
  <div class='container'>
    <h5>我的钱包</h5>
    <div class="content">
        <div class="wallet">
            <div class="records">
              <router-link to="/PurchaseRecord" style="margin-bottom:12px">
                <img src="@assets/img/profile/icon-buy.png"/>购买记录
              </router-link>
              <router-link to="/SpendRecord">
                <img src="@assets/img/profile/icon-spend.png"/>消费记录
              </router-link>
            </div>
            <div class="property">
                <div class="income">
                  <h6>￥{{myWalletInfo.income_total}}</h6>
                  <span>总收入</span>
                </div>
                <div class="balance">
                  <h6>￥{{myWalletInfo.member_wallet}}</h6>
                  <span>账户余额</span>
                  <img src="@assets/img/profile/icon-withdrawals1.png" class="b1" @click="$router.push('/requestWithdrawal')"/>
                  <img src="@assets/img/profile/icon-withdrawals2.png" class="b2"/>
                </div>
                <img src="@assets/img/profile/icon-wallet_bg.png" class="bg"/>
            </div>
        </div>
      <h4>收入明细</h4>
      <div class="order-list">
        <table>
          <colgroup>
            <col style="width:20%">
            <col style="width:40%">
            <col style="width:20%">
            <col style="width:20%">
          </colgroup>
          <thead>
            <tr>
              <th>订单号</th>
              <th>发送文件名称</th>
              <th>实付金额</th>
              <th>支付时间</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in myWalletInfo.list" :key="item.id">
              <td>{{ item.order_number }}</td>
              <td>{{item.share_title}}</td>
              <td>￥{{item.price}}</td>
              <td>{{ item.create_time }}</td>
            </tr>
          </tbody>
        </table>

      </div>
      <el-pagination
          v-if="myWalletInfo.count >10"
          :page-size="10"
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :total="myWalletInfo.count">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {myWallet} from "@assets/api/myWallet/myWallet";
import {mapState} from "vuex";

export default {
  name:'',
  components: {},

  data () {
    return {
      pageIndex:1,//分页
      myWalletInfo:{},//我的钱包信息
    };
  },

  computed: {
    ...mapState({userInfo:'userInfo'})
  },

  created(){
    this.getmyWalletData()
  },

  methods: {
    //我的钱包
    getmyWalletData(){
      let params = {
        page:this.pageIndex,
        limit:10,
      }
      myWallet(params).then(res=>{
        if(res.code == 1){
          console.log('res')
          this.myWalletInfo = res.data
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    handleCurrentChange(val){
      this.pageIndex = val
      this.getmyWalletData()
    }
  },

}

</script>
<style lang='less' scoped>
@import '@assets/css/profile/account/myWallet.less';
</style>